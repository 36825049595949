<template>
  <div class="content">
    <nav-box></nav-box>
    <!-- 第一个-->
    <div class="cnt-one w" v-if="levels === '1'">
      <p class="title">不锈钢采购公告</p>
      <p class="cnt">
        采购一批不锈钢条项目，供应商在2020年04月6号公司公告附件下载获取采购文件，并2020年09月3日11点30分（北京时间）提前交响应文件。
      </p>
      <p class="title-small">一、采购内容</p>
      <p class="cnt">
        采购一批电焊条项目，供应商在2020年06月1号公司公告附件下载获取采购文件，并2020年08月30日10点30分（北京时间）提前交响应文件。
      </p>
      <p class="title-small">二、合格投标人的资格条件</p>
      <div class="cnt">
        <p>（一）符合《中华人民共和国政府采购法》第二十二条规定应当具备的条件。</p>
        <p>（二）应自觉抵制政府采购领域商业贿赂行为。</p>
        <p>（三）本项目允许（不允许）联合体投标。</p>
        <p>（四）合格投标人还要满足的其它资格条件</p>
      </div>
      <p class="title-small">三、政府采购供应商入库须知</p>
      <div class="cnt">
        参与辽宁省省本级政府采购项目竞标的投标人未进入辽宁省政府采购供应商库的，请详阅辽宁省政府采购网站“使用帮助”中公布的“供应商用户手册”及时办理入库登记手续。
      </div>
      <p class="title-small">四、领取招标文件的时间及方式</p>
      <div class="cnt">
        即日起至2020年08月30日（节假日除外，每日8:30--11:00、13:30--16:00北京时间），供应商可从网上下载招标文件。
      </div>
      <p class="title-small">五、递交投标文件截止及开标时间，递交投标文件及开标地点</p>
      <div class="cnt">
        <p>递交投标文件截止及开标时间：2020年08月30日。</p>
        <p>递交投标文件及开标地点：云南省曲靖市师宗县工业园区大同片区</p>
        <p> 届时请供应商法人或授权代表出席开标大会。</p>
      </div>
    </div>
    <!--    第二部分-->
    <div class="cnt-one w" v-if="levels === '2'">
      <p class="title">金属采购公告</p>
      <p class="cnt">
        采购一批电焊条项目，供应商在2020年06月1号公司公告附件下载获取采购文件，并2020年08月30日10点30分（北京时间）提前交响应文件。
      </p>
      <p class="title-small">一、采购内容</p>
      <p class="cnt">
        采购一批电焊条项目，供应商在2020年06月1号公司公告附件下载获取采购文件，并2020年08月30日10点30分（北京时间）提前交响应文件。
      </p>
      <p class="title-small">二、合格投标人的资格条件</p>
      <div class="cnt">
        <p>（一）符合《中华人民共和国政府采购法》第二十二条规定应当具备的条件。</p>
        <p>（二）应自觉抵制政府采购领域商业贿赂行为。</p>
        <p>（三）本项目允许（不允许）联合体投标。</p>
        <p>（四）合格投标人还要满足的其它资格条件</p>
      </div>
      <p class="title-small">三、政府采购供应商入库须知</p>
      <div class="cnt">
        参与辽宁省省本级政府采购项目竞标的投标人未进入辽宁省政府采购供应商库的，请详阅辽宁省政府采购网站“使用帮助”中公布的“供应商用户手册”及时办理入库登记手续。
      </div>
      <p class="title-small">四、领取招标文件的时间及方式</p>
      <div class="cnt">
        即日起至2020年08月30日（节假日除外，每日8:30--11:00、13:30--16:00北京时间），供应商可从网上下载招标文件。
      </div>
      <p class="title-small">五、递交投标文件截止及开标时间，递交投标文件及开标地点</p>
      <div class="cnt">
        <p>递交投标文件截止及开标时间：2020年08月30日。</p>
        <p>递交投标文件及开标地点：云南省曲靖市师宗县工业园区大同片区</p>
        <p> 届时请供应商法人或授权代表出席开标大会。</p>
      </div>
    </div>
    <!-- 第三个-->
    <div class="cnt-one w" v-if="levels === '3'">
      <p class="title">二硫化钼锂基脂采购公告</p>
      <p class="cnt">
        采购一批电焊条项目，供应商在2020年06月1号公司公告附件下载获取采购文件，并2020年08月30日10点30分（北京时间）提前交响应文件。
      </p>
      <p class="title-small">一、采购内容</p>
      <p class="cnt">
        采购一批电焊条项目，供应商在2020年06月10号公司公告附件下载获取采购文件，并2020年08月30日10点30分（北京时间）提前交响应文件。
      </p>
      <p class="title-small">二、合格投标人的资格条件</p>
      <div class="cnt">
        <p>（一）符合《中华人民共和国政府采购法》第二十二条规定应当具备的条件。</p>
        <p>（二）应自觉抵制政府采购领域商业贿赂行为。</p>
        <p>（三）本项目允许（不允许）联合体投标。</p>
        <p>（四）合格投标人还要满足的其它资格条件</p>
      </div>
      <p class="title-small">三、政府采购供应商入库须知</p>
      <div class="cnt">
        参与辽宁省省本级政府采购项目竞标的投标人未进入辽宁省政府采购供应商库的，请详阅辽宁省政府采购网站“使用帮助”中公布的“供应商用户手册”及时办理入库登记手续。
      </div>
      <p class="title-small">四、领取招标文件的时间及方式</p>
      <div class="cnt">
        即日起至2020年08月30日（节假日除外，每日8:30--11:00、13:30--16:00北京时间），供应商可从网上下载招标文件。
      </div>
      <p class="title-small">五、递交投标文件截止及开标时间，递交投标文件及开标地点</p>
      <div class="cnt">
        <p>递交投标文件截止及开标时间：2020年06月12日。</p>
        <p>递交投标文件及开标地点：云南省曲靖市师宗县工业园区大同片区</p>
        <p> 届时请供应商法人或授权代表出席开标大会。</p>
      </div>
    </div>
    <div class="w out" @click="getOut">返回</div>
  </div>
</template>

<script>
import NavBox from '@/components/common/navBox'
export default {
  components: { NavBox },
  data () {
    return {
      levels: ''
    }
  },
  created () {
    this.getLevel()
  },
  methods: {
    getLevel () {
      this.levels = sessionStorage.getItem('level')
    },

    getOut () {
      this.$router.push('/notice')
    }
  },
  destroyed () {
    sessionStorage.removeItem('level')
  }
}
</script>

<style scoped lang="less">
.w {
  width: 1200px;
  margin: 0 auto;
}
.out {
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}
.content{
  width: 100%;
  min-height: auto;
}
.title{
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
}
.title-small{
  font-size: 16px;
  font-weight: bolder;
}
.cnt{
  font-size: 12px;
}
</style>
